<template>
	<div>
		<!-- <myheader :types="1" /> -->
		<div class="Carousel">
			<el-carousel trigger="click" height="2.81rem" :pause-on-hover="false">
				<el-carousel-item v-for="item in info.carousel" :key="item">
					<img :src="proxy.$axios.imgUrl + item.images" class="Carousel-img" />
				</el-carousel-item>
			</el-carousel>
		</div>

		<!-- <div class="index-bar flex-center">
			<div class="index-barItem">
				<div>CRO<span>Serve</span></div>
				<div>One-stop service</div>
			</div>
			<div class="index-barItem">
				<div>{{info.information?.experience}}<sup>+</sup><span>Serve</span></div>
				<div>Experience</div>
			</div>
			<div class="index-barItem">
				<div>{{info.information?.drug}}<span>+</span></div>
				<div>Drug</div>
			</div>
			<div class="index-barItem">
				<div>{{info.information?.project_experience}}<span>+</span></div>
				<div>Project experience</div>
			</div>
			<div class="index-barItem">
				<div>{{info.information?.key_projects}}<span>+</span></div>
				<div>Key projects</div>
			</div>
			<div class="index-barItem">
				<div>{{info.information?.innovation_special}}<span>+</span></div>
				<div>Innovation special</div>
			</div>
		</div> -->

		<div class="index-content flex-center">
			<div class="index-content-box">
				<div class="index-content-item">
					<div class="index-content-title alcenter">
						services
						<img src="../../assets/image/ic_titzshi.png" />
						<div class="title-border"></div>
					</div>
					<div class="index-content-list1 flex-layout">
						<div class="list1-item" @click="jump(2,item.id)" v-for="(item,index) in info.services" :key="index">
							<img :src="proxy.$axios.imgUrl + item.icon" />
							<div class="list1-item-content">{{item.title}}</div>
						</div>
					</div>
				</div>

				<div class="index-content-item">
					<div class="index-content-title alcenter">
						SAIFU Solutions
						<img src="../../assets/image/ic_titzshi.png" />
						<div class="title-border"></div>
					</div>

					<div class="index-content-list2 flex-layout" v-if="info.solutions">
						<div class="content-left hover" @click="jump(3,info.solutions[0].id)">
							<img :src="proxy.$axios.imgUrl + info.solutions[0]?.icon" class="imgbg" />
							<div class="title">{{info.solutions[0]?.title}}</div>
							<!-- <div class="alcenter">Learn more <img src="../../assets/image/ic_xybjtoub.png" /> -->
							<!-- </div> -->

						</div>
						<div class="content-conter">
							<div @click="jump(3,info.solutions[1].id)" class="hover">
								<img :src="proxy.$axios.imgUrl + info.solutions[1].icon" class="imgbg" />
								<div class="title">{{info.solutions[1].title}}</div>
								<!-- <div class="alcenter">Learn more <img src="../../assets/image/ic_xybjtoub.png" /></div> -->

							</div>
							<div @click="jump(3,info.solutions[2].id)" class="hover">
								<img :src="proxy.$axios.imgUrl + info.solutions[2].icon" class="imgbg" />
								<div class="title">{{info.solutions[2].title}}</div>
								<!-- <div class="alcenter">Learn more <img src="../../assets/image/ic_xybjtoub.png" /></div> -->

							</div>
						</div>
						<div class="content-right hover" @click="jump(3,info.solutions[3].id)">
							<img :src="proxy.$axios.imgUrl + info.solutions[3].icon" class="imgbg" />
							<div class="title">{{info.solutions[3].title}}</div>
							<!-- <div class="alcenter">Learn more <img src="../../assets/image/ic_xybjtoub.png" /></div> -->
						</div>
					</div>
				</div>

				<div class="index-content-item">
					<div class="index-content-title alcenter">
						news
						<img src="../../assets/image/ic_titzshi.png" />
						<div class="title-border"></div>
					</div>
					<div class="index-content-list3 flex" v-if="info.news">
						<div class="left" @click="jump(4,info.news[0].id,'/newsdetail')">
							<img :src="proxy.$axios.imgUrl+ info.news[0].images" />
							<div class="list3-item-content">
								<div class="title">
									{{info.news[0].title}}
								</div>
								<div class="content">
									{{info.news[0].synopsis}}
								</div>
								<div class="timer">
									{{info.news[0].date_en}}
								</div>
							</div>
						</div>
						<div class="right">
							<div class="list3-item-content" v-for="(item,index) in info.news.slice(1,3)" :key="index" @click="jump(4,item.id,'/newsdetail')">
								<div class="title">
									{{item.title}}
								</div>
								<div class="content">
									{{item.synopsis}}
								</div>
								<div class="timer">
									{{item.date_en}}
								</div>
							</div>
						</div>
					</div>

					<div class="index-content-list3-more flex-center" @click="jump(4,4,'/newsList')">
						Learn more
						<img src="../../assets/image/ic_morejt.png" />
					</div>
				</div>

			</div>
		</div>

		<!-- <myfooter></myfooter> -->
	</div>
</template>

<script setup>
	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'

	import EventBus from "../../assets/common/event-bus.js"

	onMounted(() => {
		EventBus.emit("mgheadr", 1);
		getInfo()
	})

	const {
		proxy
	} = getCurrentInstance()
	//轮播
	const info = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('api/index/getHomeData')
		info.value = res.data
	}


	const router = useRouter()

	function jump(type, id, url = '/content') {
		router.push({
			path: url,
			query: {
				type,
				id
			}
		})
	}
</script>

<style scoped>
	/*
		轮播图
	*/
	.Carousel {
		margin-top: 0.04rem;
	}

	.Carousel-img {
		width: 100%;
		margin: 0 auto;
		height: auto;
		/* height: 2.81rem; */
	}

	.index-bar {
		width: 100%;
		background-color: #F6F6F6;
		padding: 0.21rem 0 0.18rem;
		text-align: center;
	}

	/*
	*	数据bar
	*/

	.index-barItem:not(:first-child) {
		margin-left: 0.37rem;
	}

	.index-barItem>div:first-child {
		font-size: 0.15rem;
		color: #000000;
		font-weight: 500;
	}

	.index-barItem>div:first-child span {
		font-size: 0.08rem;
		font-weight: 400;
		margin-left: 0.04rem;
	}

	.index-barItem>div:first-child sup {
		font-size: 0.1rem;
	}

	.index-barItem>div:last-child {
		font-size: 0.06rem;
		color: #999999;
	}

	.index-content {
		background: url('../../assets/image/bg_wenlbj.png') no-repeat;
		background-size: 100% auto;
	}


	.index-content-item {
		margin: 0 auto;
		width: auto;
	}

	/*
	
	*/

	.index-content-title {
		font-size: 0.13rem;
		margin-top: 0.3rem;
		margin-bottom: 0.18rem;
		line-height: 0.1rem;
		position: relative;
		padding-bottom: 0.16rem;
		font-family: Source Han Sans CN;
		font-weight: bold;
		text-transform: uppercase;
	}

	.index-content-title>img {
		width: 0.07rem;
		height: 0.12rem;
		margin-left: 0.09rem;
	}

	.index-content-title .title-border {
		width: 0.23rem;
		height: 0.02rem;
		background: #DBDBDB;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.index-content-list1 .list1-item {
		width: 1.08rem;
		border: 1px solid #EEEEEE;
		background-color: #fff;
	}

	.index-content-list1 .list1-item:hover {
		color: #fff;
		background-color: #008AD2;
		box-shadow: 0px 0px 0.04rem 0px rgba(5, 95, 164, 0.46);
		cursor: pointer;
	}

	.index-content-list1 .list1-item:not(:first-child) {
		margin-left: 0.16rem;
	}

	.index-content-list1 .list1-item>img {
		width: 100%;
		height: 1.34rem;
		display: block;
	}

	.list1-item-content {
		height: 0.54rem;
		padding: 0.08rem 0.11rem;
		font-size: 0.1rem;
		line-height: 0.1	rem;
		box-sizing: border-box;
		font-family: Source Han Sans CN;
	}

	.index-content-list2,
	.index-content-list3 {
		width: 100%;
	}

	.index-content-list2 .hover {
		position: relative;
		font-size: 0.09rem;
		padding: 0.11rem 0.13rem 0.1rem 0.11rem;
		box-sizing: border-box;
		color: #000000;
		display: flex;
		flex-flow: column;
		height: 1.8rem;
		z-index: 9;
		overflow: hidden;
	}

	.index-content-list2 .hover:hover {
		cursor: pointer;
	}

	.index-content-list2 .hover:hover img {
		transform: scale(1.2);
		transition: 0.4s;
	}

	.index-content-list2 .content-left>.title,
	.index-content-list2 .content-right>.title,
	.index-content-list2 .content-conter .title {
		flex: 1;
		font-size: 0.11rem;
	}

	.index-content-list2>div .alcenter {
		font-size: 0.08rem;
		text-transform: uppercase;
	}

	.index-content-list2>div .alcenter>img {
		width: 0.24rem;
		height: 0.12rem;
		margin-left: 0.06rem;
		display: block;
	}

	.index-content-list2>div .imgbg {
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: 0.9;
		z-index: -1;
		top: 0;
		left: 0;
	}

	.index-content-list2 .content-left {
		width: 1.72rem;
	}

	.index-content-list2 .content-conter {
		justify-content: space-between;
		padding: 0;
		display: flex;
		flex-flow: column;
		height: 1.8rem;
	}


	.index-content-list2 .content-conter>div {
		width: 1.41rem;
		height: 0.84rem;
		position: relative;
		padding: 0.11rem 0.13rem 0.1rem 0.11rem;
		box-sizing: border-box;

	}

	.index-content-list2 .content-conter>div:last-child {
		margin-top: 0.08rem;
	}

	.index-content-list2 .content-conter>div>img {
		width: 1.41rem;
		height: 0.84rem;
	}

	.index-content-list2 .content-conter>div>div:first-child {
		flex: 1;
	}

	.index-content-list2 .content-right {
		width: 1.41rem;
	}

	.index-content-list3 {
		justify-content: space-between;
	}

	.index-content-list3 .left {
		border: 1px solid #EEEEEE;
		background: #FFFFFF;
	}

	.index-content-list3 .left img {
		width: 2.26rem;
		height: 1.47rem;
		display: block;
	}

	.list3-item-content {
		padding: 0.08rem 0.14rem 0.08rem 0.12rem;
		box-sizing: border-box;
	}

	.list3-item-content .title {
		font-size: 0.08rem;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		white-space: nowrap;
		padding-right: 0.22rem;
		line-height: 0.1rem;
	}

	.index-content-list3 .left .list3-item-content {
		width: 2.26rem;
	}

	.list3-item-content .content,
	.list3-item-content .timer {
		font-size: 0.05rem;
		line-height: 0.09rem;
		margin-top: 0.08rem;
		color: #666666;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.index-content-list3 .right .list3-item-content {
		width: 2.31rem;
		border: 1px solid #EEEEEE;
	}

	.index-content-list3 .right .list3-item-content:not(:first-child) {
		margin-top: 0.04rem;
	}

	.index-content-list3 .list3-item-content:hover,
	.index-content-list3 .left:hover {
		background-color: #008AD2;
		color: #fff;
		cursor: pointer;
	}

	.index-content-list3 .list3-item-content:hover .content,
	.index-content-list3 .list3-item-content:hover .timer,
	.index-content-list3 .left:hover .content,
	.index-content-list3 .left:hover .timer {
		color: #fff;
	}

	.index-content-list3 .right .list3-item-content:hover {
		box-shadow: 0px 0.02rem 0.02rem 0px rgba(8, 101, 172, 0.26);
	}

	.index-content-list3-more {
		margin-top: 0.19rem;
		color: #666666;
		font-size: 0.07rem;
		text-transform: uppercase;
		margin-bottom: 0.23rem;
	}

	.index-content-list3-more img {
		width: 0.25rem;
		height: 0.13rem;
		margin-left: 0.06rem;
		cursor: pointer;
	}
</style>